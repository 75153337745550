<template>
  <div>
    <b-row>
      <!-- Information Buyer -->
      <InfoBuyer :result="result" />
      <!-- Information Talenta -->
      <InfoTalenta :result="result" />
      <!-- Information Order -->
      <InfoOrder :result="result" />
      <!-- Information Service -->
      <InfoService :result="result" />
      <!-- Steps -->
      <Steps :result="result" />
    </b-row>
    <div
      v-if="chats"
      style="height: inherit"
    >
      <section class="chat-app-window">
        <div class="active-chat">
          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            :settings="settings"
            class="user-chats scroll-area pt-5"
            @ps-scroll-y="scrollHandle"
          >
            <div
              v-if="loading == true"
              class="chats text-center"
            >
              <b-spinner
                class="mb-2"
                variant="primary"
              />
            </div>
            <chat-log
              :loading="loading"
              :chat-data="chats"
            />
          </vue-perfect-scrollbar>
          <!-- Message Input -->
          <!-- <b-form
            class="chat-app-form"
          >
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input
                placeholder="Enter your message"
              />
            </b-input-group>
            <b-button
              variant="primary"
              type="button"
            >
              Send
            </b-button>
          </b-form> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BRow, BButton, BForm, BFormInput, BInputGroup, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import InfoBuyer from '@/components/Order/InfoBuyer.vue'
import InfoTalenta from '@/components/Order/InfoTalenta.vue'
import InfoOrder from '@/components/Order/InfoOrder.vue'
import InfoService from '@/components/Order/InfoService.vue'
import Steps from '@/components/Order/Steps.vue'
import ChatLog from '@/components/Order/ChatLog.vue'

export default {
  title() {
    return 'Detail Order'
  },
  components: {
    BRow,
    InfoBuyer,
    InfoTalenta,
    InfoOrder,
    InfoService,
    Steps,
    VuePerfectScrollbar,
    ChatLog,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BSpinner,
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      chats: [],
      page: 1,
      settings: {
        maxScrollbarLength: 120,
      },
      next_page_url: null,
      loading: false,
    }
  },
  created() {
    this.getData()
    this.getChat()
  },
  mounted() {
  },
  methods: {
    scrollHandle() {
      const chatMessages = document.querySelector('.user-chats')
      if (chatMessages.scrollTop === 0) {
        this.paginationChat()
        if (this.next_page_url !== null) {
          chatMessages.scrollTop = 60
        }
      }
    },
    scrollDown() {
      const chatMessages = document.querySelector('.user-chats')
      chatMessages.scrollTop = chatMessages.scrollHeight
    },
    getData() {
      this.$http.get(`/admin/order/${this.id}`)
        .then(response => {
          this.result = response.data.data
        })
    },
    paginationChat() {
      if (this.next_page_url !== null) {
        this.loading = true
        setTimeout(() => {
          this.$http.get(`/admin/order/${this.id}/chat?page=${this.page += 1}`)
            .then(response => {
              const chats = response.data.data.data
              this.next_page_url = response.data.data.next_page_url
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < chats.length; index++) {
                const element = chats[index]
                this.chats.splice(0, 0, element)
              }
              this.loading = false
            })
        }, 300)
      }
    },
    getChat() {
      this.$http.get(`/admin/order/${this.id}/chat`)
        .then(response => {
          this.chats = response.data.data.data.reverse()
          this.next_page_url = response.data.data.next_page_url
          setTimeout(() => {
            this.scrollDown()
          }, 100)
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.scroll-area {
  position: relative !important;
  margin: auto !important;
  height: 500px !important;
}
</style>
