<template>
  <b-col
    cols="12"
    lg="6"
  >
    <b-card
      v-if="result.talent"
      title="Talenta"
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-between">
          <div>
            <b-img
              :src="result.talent.photo_url"
              width="80"
            />
          </div>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ result.talent.name ? result.talent.name : '-' }}
              </h4>
              <span class="card-text">{{ result.talent.email }}</span>
            </div>
            <div class="d-flex">
              <b-button
                :to="{ name: 'talenta.show', params: { id: result.talent.uuid } }"
                variant="primary"
                class="btn-icon d-flex d-lg-none"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <b-button
            :to="{ name: 'talenta.show', params: { id: result.talent.uuid } }"
            variant="primary"
            class="btn-icon d-none d-lg-block"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </div>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol, BButton, BImg, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BButton,
    BImg,
    BCard,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
  },

}
</script>
