<template>
  <b-col
    cols="12"
    lg="6"
  >
    <b-card
      v-if="result.service"
      title="Order"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <table class="w-100">
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Nomor Invoice</span>
              </th>
              <td class="pb-50">
                {{ result.invoice_number ? result.invoice_number : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Talent Nomor Invoice</span>
              </th>
              <td class="pb-50">
                {{ result.talent_invoice_number ? result.talent_invoice_number : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="CircleIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Nama Jasa</span>
              </th>
              <td class="pb-50">
                {{ result.name ? result.name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Nama Paket</span>
              </th>
              <td class="pb-50">
                {{ result.package_name ? result.package_name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="DribbbleIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50">
                {{ result.status_copy ? result.status_copy : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="TrendingUpIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Total</span>
              </th>
              <td class="pb-50">
                {{ result.total !== 0 ? result.total : '-' | formatAmount }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Unit</span>
              </th>
              <td class="pb-50">
                {{ result.unit ? (result.unit == 'meet' ? 'Pertemuan' : 'Proyek') : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="MonitorIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Interaksi Jasa</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.service_interaction !== 0 ? result.service_interaction : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="SidebarIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Jenis Pelunasan</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.payment_type !== 0 ? result.payment_type : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="ShieldIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Asuransi Oleh Pembeli</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span v-if="result.insurance_by_buyer == 0">-</span>
                <span v-if="result.insurance_by_buyer > 0">{{ result.insurance_by_buyer | formatAmount }}</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="ShieldIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Asuransi Oleh Penjual</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span v-if="result.insurance_by_seller == 0">-</span>
                <span v-if="result.insurance_by_seller > 0">{{ result.insurance_by_seller | formatAmount }}</span>
              </td>
            </tr>
          </table>
          <div>
            <div class="d-flex align-items-center mb-1">
              <div>
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Deskripsi :</span>
              </div>
            </div>
            <p class="mb-0 text-justify">
              {{ result.description ? result.description : '-' }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import { BCol, BCard, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BCard,
    BRow,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
  },

}
</script>
