<template>
  <b-col
    cols="12"
    lg="6"
  >
    <b-card
      v-if="result.service"
      title="Jasa"
    >
      <b-row>
        <b-col
          cols="12"
          lg="2"
        >
          <b-img
            :src="result.service.image"
            width="80"
            alt="Card image cap"
          />
        </b-col>
        <b-col
          cols="12"
          lg="10"
        >
          <table class="w-100">
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Nama</span>
              </th>
              <td class="pb-50">
                {{ result.service.name ? result.service.name : '-' }}
              </td>
            </tr>
            <!-- <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="CoffeeIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Iklan</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span :class="result.service.is_ads == true ? 'text-success' : 'text-danger'">
                  <feather-icon
                    :icon="result.service.is_ads == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75 d-none d-lg-flex"
                  />
                </span>
              </td>
            </tr> -->
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Aktif</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span :class="result.service.is_active == true ? 'text-success' : 'text-danger'">
                  <feather-icon
                    :icon="result.service.is_active == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75 d-none d-lg-flex"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="LayersIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Unit</span>
              </th>
              <td class="pb-50">
                {{ result.service.unit ? (result.service.unit == 'meet' ? 'Pertemuan' : 'Proyek') : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="TrendingUpIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Range Harga</span>
              </th>
              <td class="pb-50">
                {{ result.service.price_range !== 0 ? result.service.price_range : '-' }}
              </td>
            </tr>
            <!-- <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="TrendingDownIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Harga Terendah</span>
              </th>
              <td class="pb-50">
                {{ result.service.lowest_price !== 0 ? result.service.lowest_price : '-' }}
              </td>
            </tr> -->
            <!-- <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75 d-none d-lg-flex"
                />
                <span class="font-weight-bold">Tanggal</span>
              </th>
              <td class="pb-50">
                {{ result.service.created_at !== 0 ? result.service.created_at : '-' }}
              </td>
            </tr> -->
          </table>
          <div class="d-flex flex-wrap">
            <!-- Action -->
            <b-dropdown
              variant="info"
              size="sm"
            >
              <template #button-content>
                Action
              </template>
              <b-dropdown-item
                v-if="checkPermission('show talent')"
                @click="goToDetail(result.service.uuid)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-75"
                />
                Detail Jasa
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol, BImg, BCard, BRow, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission } from '@/auth/utils'

export default {
  components: {
    BCol,
    BImg,
    BCard,
    BRow,
    BDropdown,
    BDropdownItem,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
  },
  setup() {
    return {
      checkPermission,
    }
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: 'jasa.show', params: { id } })
    },
  },
}
</script>
