<template>
  <b-col cols="12">
    <b-card
      title="Tahap/Pertemuan"
    >
      <div
        class="table-responsive"
      >
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Nama</th>
              <th>Mulai pada</th>
              <th>Selesai pada</th>
              <th>Akan Selesai Pada</th>
              <th>Status Pembayaran</th>
              <th>Talenta Sudah Meyelesaikan</th>
              <th>Pembeli Sudah Menerima</th>
              <th>Harga</th>
              <th>Pertemuan</th>
              <th>Tipe Pertemuan</th>
              <th>Waktu Pertemuan</th>
              <th>Gambar / Bukti Pengerjaan</th>
              <th>Deadline</th>
              <th>Alamat</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="step in result.steps"
              :key="step.uuid"
            >
              <td>
                {{ step.name ? step.name : '-' }}
              </td>
              <td>
                {{ step.start_at ? step.start_at : '-' }}
              </td>
              <td>
                {{ step.finish_at ? step.finish_at : '-' }}
              </td>
              <td>
                {{ step.will_finish_at ? step.will_finish_at : '-' }}
              </td>
              <td>
                <span class="text-capitalize text-primary badge badge-light">
                  {{ step.payment_status ? step.payment_status : '-' }}
                </span>
              </td>
              <td>
                <span :class="step.talent_is_finish == true ? 'text-success' : 'text-danger'">
                  <feather-icon
                    :icon="step.talent_is_finish == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75 d-none d-lg-flex"
                  />
                </span>
              </td>
              <td>
                <span :class="step.buyer_is_finish == true ? 'text-success' : 'text-danger'">
                  <feather-icon
                    :icon="step.buyer_is_finish == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75 d-none d-lg-flex"
                  />
                </span>
              </td>
              <td>
                <span v-if="step.price == 0">-</span>
                <span v-else>{{ step.price | formatAmount }}</span>
              </td>
              <td>
                <span :class="step.need_meet == true ? 'text-success' : 'text-danger'">
                  <feather-icon
                    :icon="step.need_meet == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75 d-none d-lg-flex"
                  />
                </span>
              </td>
              <td>
                {{ step.meet_type ? step.meet_type : '-' }}
              </td>
              <td>
                {{ step.meet_time ? step.meet_time : '-' }}
              </td>
              <td>
                <div v-if="step.images.length > 0">
                  <a
                    class="text-primary"
                    @click="showMultiple(step.images)"
                  >Lihat gambar</a>
                  <vue-easy-lightbox
                    esc-disabled
                    move-disabled
                    :visible="visible"
                    :imgs="imgs"
                    :index="index"
                    @hide="handleHide"
                  />
                </div>
                <span v-else>-</span>
              </td>
              <td>
                {{ step.deadline ? step.deadline : '-' }}
              </td>
              <td>
                {{ step.meet_location ? step.meet_location : '-' }}<br>
                <a
                  v-if="step.meet_location"
                  :href="`https://maps.google.com/?q=${step.location_latitude},${step.location_longitude}`"
                  class="font-weight-bold"
                  target="_blank"
                >
                  Buka Google Maps
                  <feather-icon
                    icon="MapPinIcon"
                    class="ml-1"
                  />
                </a>
              </td>
            </tr>
            <tr v-if="result.steps && result.steps.length == 0">
              <td
                colspan="11"
                class="text-center"
              >
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import { BCol, BCard } from 'bootstrap-vue'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    BCol,
    BCard,
    VueEasyLightbox,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
  },
  data() {
    return {
      imgs: [],
      index: 0,
      visible: false,
    }
  },
  methods: {
    showSingle() {
      this.imgs = 'http://via.placeholder.com/350x150'
      // or
      this.imgs = { title: 'this is a placeholder', src: 'http://via.placeholder.com/350x150' }
      this.show()
    },
    showMultiple(images) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < images.length; index++) {
        const element = images[index]
        this.imgs.push(
          element.file_url,
        )
      }

      this.index = 1 // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>
